<template>
  <section class="top-filter">
    <div class="mb12">
      <FilterLayout
          @doReset="resetForm"
          @doSearch="doSearch"
          ref="filterLayoutRef"
          @showMoreForm="showMoreForm"
          :moreBtnVisible="moreBtnVisible"
          :isShowMoreFlag="isShowMoreList">
        <template #customform>
<!--          <a-form
              ref="formRef"
              class="form rms-form"
              layout="vertical"
              :model="formState"
          >
            <a-form-item label="Org5.1" name="orgFiveOneList" class="rms-form-item">
              <SelectWithAll
                  v-model:value="formState.orgFiveOneList"
                  placeholder="请选择"
                  mode="multiple"
                  showArrow
                  allowClear
                  :maxTagCount="1"
                  @change="orgThreeTwoChange('orgFiveOne')"
              >
                <a-select-option
                    v-for="(item, index) in orgOpsObj.orgFiveOneOps"
                    :key="index"
                    :value="item"
                ><span :title="item">{{ item }}</span>
                </a-select-option>
              </SelectWithAll>
            </a-form-item>
            <a-form-item label="Team" name="orgThreeTwoList" class="rms-form-item">
              <SelectWithAll
                  v-model:value="formState.orgThreeTwoList"
                  placeholder="请选择"
                  mode="multiple"
                  showArrow
                  allowClear
                  :maxTagCount="1"
                  @change="orgThreeTwoChange('orgThreeTwo')"
              >
                <a-select-option
                    v-for="(item, index) in orgOpsObj.orgThreeTwoOps"
                    :key="index"
                    :value="item"
                ><span :title="item">{{ item }}</span>
                </a-select-option>
              </SelectWithAll>
            </a-form-item>

            <a-form-item label="Offering" name="orgFourOneOneList" class="rms-form-item">
              <SelectWithAll
                  v-model:value="formState.orgFourOneOneList"
                  placeholder="请选择"
                  mode="multiple"
                  showArrow
                  allowClear
                  :maxTagCount="1"
                  @change="orgThreeTwoChange('orgFourOneOne')"
              >
                <a-select-option
                    v-for="(item, index) in orgOpsObj.orgFourOneOneOps"
                    :key="index"
                    :value="item"
                ><span :title="item">{{ item }}</span>
                </a-select-option>
              </SelectWithAll>
            </a-form-item>
            <a-form-item label="Cost Ctr" name="costCtrList" class="rms-form-item">
              <SelectWithAll
                  class="form-item-inline"
                  v-model:value="formState.costCtrList"
                  placeholder="请选择"
                  mode="multiple"
                  showArrow
                  allowClear
                  :maxTagCount="1"
              >
                <a-select-option
                    v-for="(item, index) in orgOpsObj.costCtrOps"
                    :key="index"
                    :value="item"
                ><span :title="item">{{ item }}</span>
                </a-select-option>
              </SelectWithAll>
            </a-form-item>
            <a-form-item label="角色" name="roleIdList" class="rms-form-item">
              <SelectWithAll
                  class="form-item-inline"
                  v-model:value="formState.roleIdList"
                  placeholder="请选择"
                  mode="multiple"
                  showArrow
                  allowClear
                  :maxTagCount="1"
              >
                <a-select-option
                    v-for="(item, index) in roleIdOps"
                    :key="index"
                    :value="item.roleName"
                ><span :title="item">{{ item.roleName }}</span>
                </a-select-option>
              </SelectWithAll>
            </a-form-item>
          </a-form>-->
          <a-form
              ref="formRef"
              class="form rms-form"
              layout="vertical"
              :model="formState"
          >
            <template v-for="(item,index) in queryList" :key="index">
              <template v-if="index<=3">
                <a-form-item :label="item.code" name="name" class="rms-form-item">
                  <template v-if="item.type==='5'">
                    <SelectWithAll
                        v-model:value="formState[item.inputDataType]"
                        placeholder="请选择"
                        mode="multiple"
                        showArrow
                        :maxTagCount="1"
                        allowClear
                        :options="getOptions(item.inputDataType)"
                        @change="getSelectChange(item.inputDataType)"
                    >
                    </SelectWithAll>
                  </template>
                </a-form-item>
              </template>
            </template>
            <MoreFormList :visible="isShowMoreList" @open="openFormSortBox" @close="closeMoreForm" @moreFormSearch="moreSeachHanlder">
              <template v-for="(item,index) in queryList" :key="index">
                <template v-if="index>3">
                  <a-form-item :label="item.code" name="name" class="rms-form-item">
                    <template v-if="item.type==='5'">
                      <SelectWithAll
                          v-model:value="formState[item.inputDataType]"
                          placeholder="请选择"
                          mode="multiple"
                          showArrow
                          :maxTagCount="1"
                          allowClear
                          :options="getOptions(item.inputDataType)"
                          @change="getSelectChange(item.inputDataType)"
                      >
                      </SelectWithAll>
                    </template>
                  </a-form-item>
                </template>
              </template>
            </MoreFormList>
          </a-form>
        </template>
        <template #operationBtns>
          <div class="buttons-wrap right">
            <Tooltip title="导出">
              <a-button
                  class="ml12"
                  type="primary"
                  ghost
                  @click="exportClick"
                  v-auth="['system:dataPerms:export']"
              >
                <ExportOutlined />
              </a-button>
            </Tooltip>
            <Tooltip title="下载模板">
              <a-button
                  class="ml12"
                  type="primary"
                  ghost
                  @click="downloadClick"
                  v-auth="['system:dataPerms:download']"
              >
                <DownloadOutlined />
              </a-button>
            </Tooltip>
            <Tooltip title="导入">
              <a-button
                  class="ml12"
                  type="primary"
                  ghost
                  @click="uploadClick"
                  v-auth="['system:dataPerms:import']"
              >
                <ImportOutlined />
              </a-button>
            </Tooltip>
          </div>
        </template>
      </FilterLayout>
<!--      <div class="buttons-wrap">
          <a-button class="left" type="primary" ghost @click="exportClick" v-auth="['system:dataPerms:export']">
            <UploadOutlined />
            导出
          </a-button>
          <a-button class="ml12 left" type="primary" ghost @click="downloadClick" v-auth="['system:dataPerms:download']">
            <DownloadOutlined />
            下载模板
          </a-button>
          <a-button class="ml12 left" type="primary" ghost @click="uploadClick" v-auth="['system:dataPerms:import']">
            <CloudDownloadOutlined />
            导入
          </a-button>
      </div>-->
    </div>
    <FormModalBox v-model:visible="isShowFormSortBox" :checkList="checkList" @closeModal="closeModal" @reset="queryFormList"></FormModalBox>
  </section>
</template>
<script>
import { defineComponent, reactive, ref, onMounted, onActivated,nextTick } from "vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import {cloneDeep, debounce} from "lodash";
import { getValueAndOps } from "@/utils/orgSelectData";
import {
  DownloadOutlined,
  ExportOutlined,
  ImportOutlined,
} from "@ant-design/icons-vue";
import {dictionaryOpt} from "@/utils/common.js";
import Tooltip from '@/components/Tooltip'
import MoreFormList from '@/components/MoreFormList'
import FormModalBox from '@/components/FormModalBox'
export default defineComponent({
  name: "TopFilter",
  components: {
    DownloadOutlined,
    MoreFormList,
    FormModalBox,
    Tooltip,
    ExportOutlined,
    ImportOutlined },
  emits: [
    "search",
    "export-click",
    "download-click",
    "upload-click",
    "resetTable",
  ],
  setup(props, ctx) {
    const { $api } = useGlobalPropertyHook();
    const formRef = ref();
    const orgFiveOneOps = ref([]);
    const orgThreeTwoOps = ref([]);
    const orgFourOneOneOps = ref([]);
    const costCtrOps = ref([]);
    const roleIdOps = ref([]);

    const resetForm = () => {
      formRef.value.resetFields();
      Object.assign(formState,initData)
      updateParams()
      doSearch()
      getOrgList()
      ctx.emit("search", currentState);
    };

    const uploadClick = () => {
      ctx.emit("upload-click");
    };
    const downloadClick = () => {
      ctx.emit("download-click");
    };
    const exportClick = () => {
      formState.roleIdList = roleIdOps.value.filter(item => {
        if(formState.roleIdList.includes(item.roleName)){
          return item.roleId
        }
      }).map(item => item.roleId)
      ctx.emit("export-click", currentState);
      formState.roleIdList = roleIdOps.value.filter(item => {
        if(formState.roleIdList.includes(item.roleId)){
          return item.roleName
        }
      }).map(item => item.roleName)
    };

    let currentState  = {}
    const updateParams =() => {
      currentState = cloneDeep(formState);
    }
    let initData = {
      orgFiveOne: [],
      orgThreeTwo: [],
      orgFourOneOne: [],
      roleIdList: [],
      costCtr: [],
    }
    const formState = reactive({...initData});
    const orgOpsObj = reactive({
      orgFiveOneOps: [],
      orgThreeTwoOps: [],
      orgFourOneOneOps: [],
      costCtrOps: [],
    });


    const doSearch = async () => {
      formState.roleIdList = roleIdOps.value.filter(item => {
        if(formState.roleIdList.includes(item.roleName)){
          return item.roleId
        }
      }).map(item => item.roleId)
      updateParams()
      await ctx.emit("search", currentState);
      formState.roleIdList = roleIdOps.value.filter(item => {
        if(formState.roleIdList.includes(item.roleId)){
          return item.roleName
        }
      }).map(item => item.roleName)
    };
    //获取org下拉筛选框
    const getOrgList = () => {
      $api.getOrgTwo_Three_Four_FiveList().then((res) => {
        const { orgFiveOneList, orgThreeTwoList, orgFourOneOneList, costCtrList }  = res.data;
        orgOpsObj.orgFiveOneOps = dictionaryOpt(orgFiveOneList);
        orgOpsObj.orgThreeTwoOps = dictionaryOpt(orgThreeTwoList);
        orgOpsObj.orgFourOneOneOps = dictionaryOpt(orgFourOneOneList);
        orgOpsObj.costCtrOps = dictionaryOpt(costCtrList);
        costCtrOps.value = dictionaryOpt(costCtrList);
      })
    };

    const filterOption = (input, option) => {
      return (
        option.children[0].children
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    };

    //字段联动规则
    const orgAllList = ref([])
    const getOrgRelatedList = () => {
      $api.getAllOrgRelatedList().then((res) => {
        orgAllList.value = res.data
      })
    }
    const orgThreeTwoChange = (type) => {
      const formOrgState = {
        orgFiveOneList: formState.orgFiveOne,
        orgThreeTwoList: formState.orgThreeTwo,
        orgFourOneOneList: formState.orgFourOneOne
      }
      const {orgsValue, orgOpsList} = getValueAndOps(orgAllList.value,formOrgState,orgOpsObj,type)
      Object.assign(formState,orgsValue)
      Object.assign(orgOpsObj,orgOpsList)
      if(type === 'orgFourOneOne') {
        formState.costCtr = []
        const temp = {}
        formState.orgFourOneOne.forEach(item => temp[item] = true)
        orgOpsObj.costCtrOps = dictionaryOpt(orgAllList.value.filter(item => temp[item.orgFourOneOne]).map(item => item.costCtr))
        if(formState.orgFourOneOne.length === 0){
          orgOpsObj.costCtrOps = costCtrOps.value
        }
      }
    };
    const moreSeachHanlder = () => {
      doSearch()
      closeMoreForm()
    }
    const getRoleIdList = () => {
      $api.getRoleList({status:'enable'}).then((res) => {
        roleIdOps.value = res.data.filter(item => item.roleName !== 'PM' && item.roleName !== 'LM' && item.roleName !== '顾问')
        roleIdOps.value = roleIdOps.value.map((item,index)=>{
          return {
            ...item,
            label: item.roleName,
            value: item.roleName,
            key: index,
          }
        })
      })
    }

    const queryList = ref([])
    const checkList = ref([])
    const moreBtnVisible = ref(false)
    const queryFormList = (data) => {
      let params = {
        firstMenu: 'systemSettings',
        secondMenu: 'dataPerm',
        isQueryDefault: data.isQueryDefault
      }
      $api.queryFilterSort(params).then((res) => {
        if(data.isQuery){
          queryList.value = res.data.filter((item)=>{
            return item.isDeleted==='0'
          })
          queryList.value.length > 4?moreBtnVisible.value = true :moreBtnVisible.value = false
          checkList.value=[...queryList.value]
        }else{
          checkList.value = res.data.filter((item)=>{
            return item.isDeleted==='0'
          })
        }
      });
    };
    const getOptions = (data)=> {
      switch (data) {
        case 'orgThreeTwo':
          return orgOpsObj.orgThreeTwoOps
        case 'orgFourOneOne':
          return orgOpsObj.orgFourOneOneOps
        case 'orgFiveOne':
          return orgOpsObj.orgFiveOneOps
        case 'costCtr':
          return orgOpsObj.costCtrOps
        case 'roleIdList':
          return roleIdOps.value
        default :
          ''  // 如果没
      }
    }
    const getSelectChange = debounce((data)=> {
      console.log(data)
      switch (data) {
        case 'orgThreeTwo':
          return orgThreeTwoChange('orgThreeTwo')
        case 'orgFourOneOne':
          return orgThreeTwoChange('orgFourOneOne')
        case 'orgFiveOne':
          return orgThreeTwoChange('orgFiveOne')
        default :
          ''  // 如果没
      }
    },500)
    const isShowMoreList = ref(false)
    const isShowFormSortBox = ref(false)
    const showMoreForm = (data) => {
      isShowMoreList.value = data
    }
    const openFormSortBox = () => {
      isShowFormSortBox.value = true
    }
    const closeMoreForm = (data) => {
      if(data){
        console.log(123)
      }
      isShowMoreList.value = false
    }
    const closeModal = ()=> {
      isShowFormSortBox.value = false
    }
    onMounted(() => {
      nextTick(doSearch);
      getOrgRelatedList();
      getRoleIdList();
      getOrgList();
      setTimeout(queryFormList({isQueryDefault:0,isQuery: true}),500)
    });
    onActivated(() => {
      ctx.emit("resetTable");
    });

    return {
      formState,
      formRef,
      doSearch,
      resetForm,
      roleIdOps,
      costCtrOps,
      filterOption,
      updateParams,
      orgOpsObj,
      orgFiveOneOps,
      orgThreeTwoOps,
      orgFourOneOneOps,
      orgAllList,
      getRoleIdList,
      getOrgList,
      orgThreeTwoChange,
      uploadClick,
      downloadClick,
      exportClick,
      queryFormList,
      queryList,
      checkList,
      getOptions,
      getSelectChange,
      isShowMoreList,
      showMoreForm,
      moreBtnVisible,
      openFormSortBox,
      isShowFormSortBox,
      closeMoreForm,
      closeModal,
      moreSeachHanlder
    };
  },
});
</script>
<style scoped lang="less">
.top-filter {
  width: 100%;
}
.buttons-wrap{
  :deep(.anticon){
    font-size: 20px;
  }
}
</style>
