<template>
  <section class="app-container" v-show="visible">
    <Filter 
      @search="handleSearch"
      @download-click="downloadClick"
      @upload-click="uploadClick"
      @export-click="exportClick"
      @resetTable="handleResetTable"
    ></Filter>
    <import-modal
      v-model:visible="modalVisible"
      apiFuncName="dataPermsImport"
      @closeModal="handleCloseModal"
      @resetTable="handleResetTable"
    />
    <List ref="listRef" @edit-click="editClick"></List>
    <edit-data-perms ref="editPersonRef" @refresh-table="refreshTable" />
  </section>
</template>
<script>
import { defineComponent, onActivated, ref } from "vue";
import Filter from "./components/Filter.vue";
import List from "./components/List";
import EditDataPerms from "@/views/home/ConfigManagement/DataPerms/DataPermsByCostCtr/components/EditDataPerms";
import { useGlobalPropertyHook } from "../../../../../hooks/common";
import { downloadFile } from "@/utils/common";
import ImportModal from "@/components/ImportModal";

export default defineComponent({
  name: "maintainDataPermsByCostCtr",
  components: {
    EditDataPerms,
    List,
    Filter,
    ImportModal,
  },
  setup(props, ctx) {
    const { $api } = useGlobalPropertyHook();
    const listRef = ref();
    const editPersonRef = ref();
    let visible = ref(true);
    const { $EventBus } = useGlobalPropertyHook();

    const handleSearch = (searchParams) => {
      listRef.value.searchClick(searchParams);
    };
    const refreshTable = () => {
      listRef.value.doSearch();
    };
    const editClick = (row, editOrAdd) => {
      editPersonRef.value.editClick(row, editOrAdd);
    };
    $EventBus.on("refreshDataPerms", () => {
      refreshTable();
    });

    const modalVisible = ref(false);
    const uploadClick = () => {
      modalVisible.value = true;
    };
    const handleCloseModal = () => {
      modalVisible.value = false;
    };
    const handleResetTable = () => {
      listRef.value.doSearch();
    };

    const downloadClick = () => {
      $api.dataPermsDownload().then((res) => {
        downloadFile(res.file, res.filename);
      });
    };
    const exportClick = (searchParams) => {
      const params = {
        orgFiveOne: searchParams.orgFiveOneList,
        orgThreeTwo: searchParams.orgThreeTwoList,
        orgFourOneOne: searchParams.orgFourOneOneList,
        roleIdList: searchParams.roleIdList,
        costCtr: searchParams.costCtrList,
      }
      $api.dataPermsExport(params).then((res) => {
        downloadFile(res.file, res.filename);
      });
    };

    onActivated(() => {
      ctx.emit("refresh-table");
    });
    return {
      listRef,
      editPersonRef,
      handleSearch,
      refreshTable,
      editClick,
      visible,
      modalVisible,
      uploadClick,
      downloadClick,
      exportClick,
      handleCloseModal,
      handleResetTable,
    };
  },
});
</script>
