<template>
  <section class="org-list">
    <a-table
      ref="listRef"
      :columns="columns"
      :data-source="dataPermsByCostCtrList"
      :rowKey="
        (record) => {
          record.id + record.role;
        }
      "
      :scroll="{
        y: tableHeight,
        scrollToFirstRowOnChange: true,
      }"
      :pagination="pagination"
    >
      <template #operation="{ record }">
<!--        <a-space>
          &lt;!&ndash; <span class="operationLink" @click="editClick(record, 'isAdd')">
            新增其它角色
          </span> &ndash;&gt;
          <span class="operationLink underline" @click="editClick(record, 'isEdit')" v-auth="['system:dataPerms:edit']">
            编辑
          </span>
        </a-space>-->
        <ActionBtn v-bind="record" :btnList="btnList" @FormOutlined="editClick(record, 'isEdit')" />
      </template>
    </a-table>
  </section>
</template>
<script>
import {
  defineComponent,
  nextTick,
  onMounted,
  onUnmounted,
  reactive,
  ref,
} from "vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import { useTableHeight } from "@/utils/common";
import ActionBtn from '@/components/ActionBtn'

const btnList = [
  {
    name: '编辑',
    icon: 'FormOutlined',
    auth: ['system:dataPerms:edit']
  },
]
export default defineComponent({
  name: "DataPermByCostCtrList",
  components: {ActionBtn},
  emits: ["edit-click"],
  setup(props, ctx) {
    const { $api } = useGlobalPropertyHook();

    const listRef = ref();
    const columns = [
      {
        title: "Org5.1",
        dataIndex: "orgFiveOne",
        key: "orgFiveOne",
        width: 150,
        ellipsis: true,
      },
      {
        title: "Team",
        dataIndex: "orgThreeTwo",
        key: "orgThreeTwo",
        width: 150,
        ellipsis: true,
      },
      {
        title: "Offering",
        dataIndex: "orgFourOneOne",
        key: "orgFourOneOne",
        width: 200,
        ellipsis: true,
      },
      {
        title: "Cost Ctr",
        dataIndex: "costCtr",
        key: "costCtr",
        width: 150,
        ellipsis: true,
      },
      {
        title: "角色",
        dataIndex: "roleName",
        key: "roleName",
        width: 150,
        ellipsis: true,
      },
      {
        title: "用户名称",
        dataIndex: "person",
        key: "person",
        ellipsis: true,
      },
      {
        title: "操作",
        dataIndex: "operation",
        width: 80,
        slots: {
          customRender: "operation",
        },
        fixed: "right",
      },
    ];
    const pagination = reactive({
      current: 1,
      pageSize: 12,
      pageSizeOptions: ['12','20','30','40'],
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
      onChange: (page) => {
        pagination.current = page;
        doSearch(searchCondition);
      },
      onShowSizeChange: (_, size) => {
        pagination.pageSize = size;
        pagination.current = 1;
        doSearch(searchCondition);
      },
    });
    const reviewerList = ref();
    const dataPermsByCostCtrList = ref();
    let searchCondition = {};

    const doSearch = (data) => {
      console.log(data,'')
      const params = {
        pageIndex: pagination.current,
        pageSize: pagination.pageSize,
        ...data
      };
      $api.getDataPermByCostCtrList(params).then((res) => {
        pagination.total = res.data.total;
        dataPermsByCostCtrList.value = res.data.resource;
      });
    };

    const searchClick = (searchParams) => {
      pagination.current = 1;
      searchCondition = searchParams;
      doSearch(searchCondition);
    };

    const editClick = (record, editOrAdd) => {
      ctx.emit("edit-click", record, editOrAdd);
    };

    const tableHeight = ref(0);
    const getSize = () => {
      nextTick(() => {
        tableHeight.value = useTableHeight();
      });
    };

    window.addEventListener("resize", getSize);

    onMounted(() => {
      getSize();
    });

    onUnmounted(() => {
      window.removeEventListener("resize", getSize);
    });

    return {
      listRef,
      columns,
      pagination,
      reviewerList,
      dataPermsByCostCtrList,
      searchClick,
      doSearch,
      editClick,
      tableHeight,
      btnList
    };
  },
});
</script>
<style scoped lang="less">
// 覆盖ant-design样式
::v-deep .ant-table-body {
  overflow: auto;
}

:deep(.ant-table-thead) {
  > tr > th {
    white-space: nowrap;
  }
}

:deep(.ant-table-tbody) {
  > tr > td {
    white-space: nowrap;
  }
}

.org-list {
  display: flex;
  width: 100%;
  max-height: calc(100% - 88px);

  .ant-table-wrapper {
    flex: 1;
    width: 0;
  }

  .org-info {
    width: 300px;
    //margin-left: 20px;
    background: #f4f4f4;
  }
}
</style>
