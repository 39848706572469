<template>
  <a-modal
    :getContainer="getRefsAff"
    width="40.6vw"
    :visible="visible"
    @cancel="closeModal"
    class="fill-assignment"
    centered
  >
    <template #title>
      <strong class="modal-title"> 选择用户 </strong>
    </template>
    <template #footer>
      <div class="footerBtn">
        <a-button @click="closeModal" class="cancelBtn">取消 </a-button>
        <a-button @click="submitModal" type="primary" class="addEmpBtn">
          确定
        </a-button>
      </div>
    </template>
    <a-form layout="vertical" :model="formData" :rules="formRules" ref="formRef" class="org-form">
      <div class="form-part">
        <a-form-item label="角色" name="roleName">
          <a-select
            style="width: 150px"
            v-model:value="formData.roleName"
            placeholder="请选择"
            allowClear
            :disabled="showRoleSelect"
          >
            <a-select-option v-for="item in roleList" :key="item" :value="item">
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <!--        <div class="review-info">角色: {{ formData.roleName }}</div>-->
        <div class="review-info">Cost Ctr: {{ formData.costCtr }}</div>
        <div class="review-info">Cost Center: {{ formData.costCenter }}</div>
        <a-form-item label="" name="personIdList">
          <a-select
            ref="selectPersonRef"
            v-model:value="formData.personIdList"
            placeholder="请选择"
            showArrow
            allowClear
            :filterOption="filterOption"
            mode="multiple"
            :maxTagCount="1"
            :listHeight="190"
            @select="handleRoleSelect"
          >
            <a-select-option
              v-for="item in personOps"
              :value="item.id"
              :key="item.id"
            >
              {{ item.fullName + " " + item.email }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </div>
    </a-form>
    <div class="select-reviewer">
      <div class="title">选中人员预览</div>
      <a-table
        :data-source="choosePersonList"
        :columns="columns"
        rowKey="id"
        :scroll="{
          y: tableHeight,
          scrollToFirstRowOnChange: true,
        }"
        :pagination="false"
      >
        <template #operation="{ record }">
          <a-space>
            <span class="redLink underline" @click="delClick(record)" v-auth="['system:dataPerms:delete']"> 删除 </span>
          </a-space>
        </template>
      </a-table>
    </div>
  </a-modal>
</template>

<script>
import { computed, defineComponent, onMounted, ref } from "vue";
import { message } from "ant-design-vue";
import { useGlobalPropertyHook } from "@/hooks/common";

export default defineComponent({
  name: "EditDataPerms",
  props: {
    title: String,
  },
  emits: ["refresh-table"],
  setup(props, ctx) {
    const { $api } = useGlobalPropertyHook();
    const { $EventBus } = useGlobalPropertyHook();

    const selectPersonRef = ref();

    const visible = ref(false);
    const formRef = ref();
    const isEditOrAdd = ref();
    const formData = ref({
      personIdList: [],
    });
    const formRules = {
      roleName: [
        {
          required: true,
          message: "Please select role",
          trigger: "change",
        },
      ],
      personIdList: [
        {
          required: true,
          message: "Please select Person",
          trigger: "change",
          type: "array",
        },
      ],
    };

    const personOps = ref([]);
    let showRoleSelect = ref(true);
    const roleList = ref([]);

    const getRolePermListData = async(row) => {
      const params = {
        costCtr: row.costCtr,
      };
      await $api.getNotHaveRoles(params).then((res) => {
        roleList.value = res.data;
      });
    };

    const getEMOptions = () => {
      $EventBus.on("getAllStaffs", (allStaffs) => {
        personOps.value = allStaffs;
      });
    };

    const getPersonIdList = () => {
      const params = {
        roleType: formData.value.roleName,
        costCtr: formData.value.costCtr,
      };
      $api.getCtrPersonList(params).then((res) => {
        formData.value.personIdList = res.data;
      });
    };

    const choosePersonList = computed(() =>
      personOps.value.filter(
        (item) => formData.value.personIdList.indexOf(item.id) !== -1
      )
    );

    const editClick = async (row, editOrAdd) => {
      isEditOrAdd.value = editOrAdd;

      formData.value.costCtr = row.costCtr;
      formData.value.costCenter = row.costCenter;

      if (isEditOrAdd.value == "isAdd") {
        await getRolePermListData(row);
        if(roleList.value?.length > 0) {
          visible.value = true;
          showRoleSelect.value = false;
        }else {
          message.error("当前Cost Ctr所有角色都已存在，无可新增的角色！")
        }
      } else {
        formData.value.roleName = row.roleName;

        if (row.roleName == null || row.roleName == "") {
          showRoleSelect.value = false;
        } else {
          showRoleSelect.value = true;
          getPersonIdList();
        }
        visible.value = true;
      }

    };
    const selectUser = ref([])
    const handleRoleSelect = (selectVal) => {
      selectUser.value = personOps.value.find(item => {
        const userStr = item.id;
        return selectVal === userStr;
      })
      const params = {
        roleName: [formData.value.roleName],
        userId: selectUser.value.id
      }
      $api.checkRoleMutex(params).then((res) => {
        if(res.data === 0){
          message.error(res.msg);
          const index = formData.value.personIdList.indexOf(selectVal);
          formData.value.personIdList.splice(index, 1);
        }
      });
    }

    const closeModal = () => {
      visible.value = false;
      formRef.value.resetFields();
      formData.value = {
        personIdList: [],
      };
    };

    const submitModal = () => {
      formRef.value.validate().then(() => {
        const params = {
          costCtr: formData.value.costCtr,
          roleType: formData.value.roleName,
          personList: formData.value.personIdList,
        };
        $api.savePerson(params).then(() => {
          closeModal();
          ctx.emit("refresh-table");
          message.success("操作成功");
        });
      });
    };

    const getRefsAff = () => {
      let app = document.getElementById("main-page");
      if (app) {
        return app;
      }
    };

    const filterOption = (input, option) => {
      return (
        option.children[0].children
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    };

    const columns = [
      {
        title: "ID Number",
        dataIndex: "idNumber",
        key: "idNumber",
        ellipsis: true,
        width: 70,
      },
      {
        title: "Name",
        dataIndex: "fullName",
        key: "fullName",
        ellipsis: true,
        width: 70,
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        ellipsis: true,
        width: 140,
      },
      {
        title: "操作",
        dataIndex: "operation",
        width: 80,
        ellipsis: true,
        slots: {
          customRender: "operation",
        },
        fixed: "right",
      },
    ];

    const delClick = (record) => {
      const index = formData.value.personIdList.indexOf(record.id);
      formData.value.personIdList.splice(index, 1);
    };

    const tableHeight = ref(0);

    onMounted(() => {
      const clientHeight = document.body.clientHeight;
      tableHeight.value = clientHeight * 0.4;
      getEMOptions();
    });

    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 18 },
      closeModal,
      submitModal,
      visible,
      formData,
      formRef,
      formRules,
      personOps,
      choosePersonList,
      getRefsAff,
      editClick,
      selectPersonRef,
      filterOption,
      columns,
      tableHeight,
      delClick,
      roleList,
      getRolePermListData,
      getPersonIdList,
      showRoleSelect,
      isEditOrAdd,
      selectUser,
      handleRoleSelect,
    };
  },
});
</script>

<style lang="less">
.fill-assignment {
  width: 50vw;

  .ant-modal-body {
    max-height: 798px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    &::-webkit-scrollbar-thumb {
      width: 10px;
      height: 10px;
      background: #d0e8ff;
      border: 1px solid #d0e8ff;
      border-radius: 5px;
    }

    .ant-table-body {
      overflow: auto;

      &::-webkit-scrollbar {
        width: 13px;
        height: 13px;
      }

      &::-webkit-scrollbar-thumb {
        width: 13px;
        height: 13px;
        background: #d0e8ff;
        border: 1px solid #d0e8ff;
        border-radius: 5px;
      }
    }
  }

  .footerBtn {
    text-align: right;
    position: relative;

    .cancelBtn,
    .addEmpBtn {
      margin-left: 15px;
    }

    .deactivate-btn {
      position: absolute;
      top: 10px;
      left: 0;
    }
  }

  .select-reviewer {
    margin-bottom: 20px;

    .title {
      margin-bottom: 10px;
      font-weight: bold;
    }
  }

  .review-info {
    margin-bottom: 10px;
  }
}
</style>
